<template>
  <div>
    <vx-card card-background="primary" content-color="white" title-color="white" title="En la Red tu seguridad es primero." class="mb-5" >
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>
            En esta sección podrás fortalecer tu cuenta por medio de diferentes configuraciones que te permitirán acceder a ella de forma fácil y segura.
            Recuerda que RedGirasol nunca te solicitará por correo o teléfono tu usuario y/o contraseña.
          </p>
          <p class="mt-4 mb-4">
            Descubre más información sobre cómo mantener segura tu cuenta.
          </p>
          <vs-button type="border" color="#fff" class="px-4" @click="goToSecurityTips">Sugerencias de seguridad</vs-button>
        </div>
      </div>
    </vx-card>

    <!-- PASSWORD CARD -->
    <vx-card title="Tu contraseña." class="mt-base" v-if="isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">Procura actualizar tu contraseña regularmente siguiendo las indicaciones estipuladas y ¡no la compartas con nadie! A su vez,
            RedGirasol nunca te solicitará tu contraseña por correo electrónico o a través de una llamada telefónica.</p>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button @click="goToPasswordPage">Actualizar</vs-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </vx-card>
    <!-- END PASSWORD CARD -->

    <!-- 2FA CARD -->
    <vx-card title="Segundo Factor de Autenticación." class="mt-base" v-if="isMounted">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="mb-4">El Segundo Factor de Autenticación es una de las mejores formas de garantizar que tu cuenta se mantenga segura.
            Al activarlo, aseguras que sólo tú autorices tus operaciones. En caso de que pierdas tu contraseña, tu cuenta permanecerá segura.</p>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="flex flex-wrap items-center">
                <vs-button @click="goToAuth2FaPage">{{ factorEnabled ? "Deshabilitar" : "Habilitar" }}</vs-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </vx-card>
    <!-- END 2FA CARD -->

    <!-- TRUSTED DEVICES CARD -->
    <TrustedDeviceManagement v-if="isMounted"/>
    <!-- END TRUSTED DEVICES CARD -->
  </div>
</template>

<script>
import TrustedDeviceManagement from '@/components/auth/trusted-devices/TrustedDeviceManagement';
export default {
  components: {TrustedDeviceManagement},
  async mounted() {
    this.isMounted = false
    this.showLoading(true);
    await this.getUserData();
    this.showLoading(false);
    this.isMounted = true
  },
  data() {
    return {
      isMounted: false,
      factorEnabled: false,
    }
  },
  methods: {
    async getUserData(){
      try {
        const res = await axios.get(`api/perfil/solar-id/${this.UserId}`);
        this.factorEnabled = res.data.factorEnabled;
      }
      catch (error) {
        this.warn(error);
      }
    },
    async goToPasswordPage(){
      await this.$router.push({name: 'passwordSolicitante'});
    },
    async goToAuth2FaPage(){
      await this.$router.push({name: 'auth2FaSolicitante'});
    },
    async goToSecurityTips(){
      await this.$router.push({name: 'seguridadSolicitantes'});
    }
  }
}
</script>